import { useEffect } from "react";

let changeInterval = null;
/*
maîtres d’oeuvres
indépendants
restaurateurs
garagistes
peintres
maçons
graphistes
designers
consultants
pâtissiers
charpentiers
développeurs
architectes
comptables
carreleurs
paysagistes
formateurs
horlogers
détectives
toiletteurs
vétérinaires
ostéopathes
dentistes
coachs
courtiers
conducteurs
bureaux
engins de chantier 
entrepôts
usines
*/
const wordArray = [
  "des entreprises",
  "des indépendants",
  "des restaurateurs",
  "des garagistes",
  "des peintres",
  "des maçons",
  "des graphistes",
  "des designers",
  "des consultants",
  "des pâtissiers",
  "des charpentiers",
  "des développeurs",
  "des architectes",
  "des comptables",
  "des paysagistes",
  "des formateurs",
  "des horlogers",
  "des détectives",
  "des toiletteurs",
  "des vétérinaires",
  "des ostéopathes",
  "des dentistes",
  "des coachs",
  "des courtiers",
  "des conducteurs",
  "des bureaux",
  "des entrepôts",
  "des usines",
  "des médecins",
  "des prestataires",
  "des engins",
  "des centrales",
  "des start-ups",
];
//randomized wordArray
wordArray.sort(() => Math.random() - 0.5);

const WordChange = () => {
  const animateLetterOut = (cw, i) => {
    setTimeout(function () {
      cw[i].className = "letter out";
    }, i * 80);
  };

  const animateLetterIn = (nw, i) => {
    setTimeout(function () {
      nw[i].className = "letter in";
    }, 340 + i * 80); // delay of 340, so that new letters ("design") start falling down once the first animation is completed.
  };

  useEffect(() => {
    let words = document.getElementsByClassName("word");

    let wordArray = []; //will store arrays of letters for each word
    let currentWord = 0; //will store the index of the currently displayed word;
    words[currentWord].style.opacity = 1;

    const splitLetters = (word) => {
      let content = word.innerText;
      word.innerText = "";
      let letters = [];
      for (let i = 0; i < content.length; i++) {
        let letter = document.createElement("span");
        if (content.charAt(i) === " ") {
          // Check if the character is a space
          letter.innerHTML = "&nbsp;"; // Preserve space
        } else {
          letter.className = "letter";
          letter.innerText = content.charAt(i);
        }
        word.appendChild(letter);
        letters.push(letter);
      }
      wordArray.push(letters);
    };
    for (let i = 0; i < words.length; i++) {
      splitLetters(words[i]);
    }

    const changeWord = () => {
      let cw = wordArray[currentWord]; // wordArray[0] gives us: [c,o,d,e]
      let nw =
        currentWord == words.length - 1
          ? wordArray[0]
          : wordArray[currentWord + 1]; // evals to wordArray[1] and gives us: [d,e,s,i,g,n]

      for (let i = 0; i < cw.length; i++) {
        animateLetterOut(cw, i); // called for each letter of [c,o,d,e] with different i values, so we have a delay between each letter when they fade out.
      }

      for (let i = 0; i < nw.length; i++) {
        //for each letter inside [d,e,s,i,g,n]
        nw[i].className = "letter behind"; //we set initial position to the top
        nw[0].parentElement.style.opacity = 1; //we set the opacity to 1, but currently invisible due to overlow hidden.
        animateLetterIn(nw, i); //animates each letter as if they fall down from top.
      }
      //update currentWord index.
      currentWord = currentWord == wordArray.length - 1 ? 0 : currentWord + 1;
    };
    // Call the function, sit back and enjoy the show!

    // changeWord(); //initial call
    changeInterval = setInterval(changeWord, 4000); //call every 4s bac

    return () => {
      clearInterval(changeInterval);
    };
  }, []);

  return (
    <div className="word-change">
      <p>
        {wordArray.map((word, index) => (
          <span key={index} className="word">
            {word}
          </span>
        ))}
      </p>
    </div>
  );
};

export default WordChange;
